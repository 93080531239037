import React from 'react'
import BasicContent from './BasicContent';

function KathmanduUniversity() {

    return (
            <BasicContent markedDownDocId='3' />
    )

}

export default KathmanduUniversity
