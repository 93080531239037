import React, { Component } from 'react'
import { Card, CardDeck } from 'react-bootstrap';
import BasicContent from './BasicContent';
import Blog from './Blog';

function About() {

    return (
            <BasicContent markedDownDocId='2' />
            // <Blog id='2' />

    )

}

export default About
